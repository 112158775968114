import React from 'react';
import {
    LockOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';


const BlockControl = ({ icon, dynamicIconProps }) => {
    return (
        <div className="lock">
            <span style={{ position: 'absolute', right: 0 }}><LockOutlined /></span>
            <Icon style={{ opacity: .3 }} {...dynamicIconProps} component={icon} />
        </div>
    )
}

export default BlockControl