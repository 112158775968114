import { OnboardingActionTypes } from 'actions/onboarding-actions';
import { IOnboardingState } from './interfaces';


const INITIAL_STATE: IOnboardingState = {
    run: false,
    stepIndex: 0,
    steps: [],
    start_onboarding: true,
    enabled: false,
    visibleMenu: false
};

export default function (state: IOnboardingState = INITIAL_STATE, action: any): IOnboardingState {
    switch (action.type) {
        case OnboardingActionTypes.SET_RUN: {
            return {
                ...state,
                run: action.payload
            };
        }
        case OnboardingActionTypes.SET_CURRENT_INDEX: {
            return {
                ...state,
                stepIndex: action.payload
            }
        }
        case OnboardingActionTypes.SET_STEPS: {
            return {
                ...state,
                steps: action.payload,
                visibleMenu: false
            }
        }
        case OnboardingActionTypes.SET_CURRENT_INDEX_INCREMENT: {
            if (state.enabled === true) {
                return state
            }
            return {
                ...state,
                stepIndex: state.stepIndex + 1
            }
        }
        case OnboardingActionTypes.FINISH_ONBOARDING: {
            return {
                ...state,
                start_onboarding: action.payload
            }
        }
        case OnboardingActionTypes.SET_ENABLED: {
            return {
                ...state,
                enabled: action.payload
            }
        }
        case OnboardingActionTypes.SET_VISIBLE_MENU: {
            return {
                ...state,
                visibleMenu: action.payload
            }
        }
        default:
            return state;
    }
}
