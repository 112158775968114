import React, { useEffect, useRef } from 'react';
import { CombinedState, TIMETRACKER, TIMETRACKER_TYPES } from 'reducers/interfaces';
import { useSelector } from 'react-redux';


const INTERVAL_TIME = 60000;

interface IProps {
    setTimeTracker(data: any): void;
    userType: TIMETRACKER_TYPES
}

const TimeTracker = ({ setTimeTracker, userType }: IProps): void => {

    const startWorking = useRef<number>(0);
    const lastActivities = useRef<number>(0);
    const trackerPause = useRef<boolean>(true);
    const { annotations, drawing, canvas } = useSelector((state: CombinedState) => state.annotation);
    let timeout: any;

    useEffect(() => {
        clearTimeout(timeout)
        timeout = setTimeout(checkIdleTime, INTERVAL_TIME);

        if (trackerPause.current === true) {
            startTracker()
        } else {
            updateLastActivitieTracker()
        }
    }, [annotations, canvas.activeControl])

    useEffect(() => {
        window.addEventListener("blur", stopTracker);
        return () => {
            window.removeEventListener("blur", stopTracker);
        };
    }, []);

    function startTracker() {
        startWorking.current = Date.now();
        trackerPause.current = false
        lastActivities.current = 0
    }

    function updateLastActivitieTracker() {
        lastActivities.current = Date.now()
    }

    function clearTracker() {
        startWorking.current = 0
        lastActivities.current = 0;
        trackerPause.current = true
    }

    function checkIdleTime() {
        if (lastActivities.current !== 0) {
            let lastTime = +((Date.now() - lastActivities.current) / 1000).toFixed(3);

            if (lastTime >= (INTERVAL_TIME / 500 - 1)) {
                stopTracker()
            }
        }
    }

    function stopTracker() {
        let gapTime = ((lastActivities.current - startWorking.current) / 1000).toFixed(0);
        let dataToSet = { [userType]: +gapTime }

        if (+gapTime > 0) {
            setTimeTracker(dataToSet)
        }
        clearTracker()
    }

    return <></>
}

export default TimeTracker;