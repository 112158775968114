import { ActiveControl } from 'reducers/interfaces';

export enum StepsTarget {
    STEP_1 = 'step-1',
    STEP_2 = 'step-2',
    STEP_3 = 'step-3',
    STEP_4 = 'step-4',
    STEP_5 = 'step-5',
    STEP_6 = 'step-6',
    STEP_7 = 'step-7',
    STEP_8 = 'step-8',
    STEP_9 = 'step-9',
    STEP_10 = 'step-10',
    STEP_11 = 'step-11',
    STEP_12 = 'step-12',
    STEP_13 = 'step-13',
    STEP_14 = 'step-14',
    STEP_15 = 'step-15',
    STEP_16 = 'step-16',
    STEP_17 = 'step-17',
    STEP_18 = 'step-18',
    STEP_19 = 'step-19',
    STEP_20 = 'step-20',
    STEP_21 = 'step-21',
    STEP_22 = 'step-22',
    STEP_23 = 'step-23',
}

export const modalStepsFullWidth = [0, 3, 5, 10, 14, 18, 19];


interface IAction {
    [key: number]: {
        step: string;
        length: number;
    };
};

type ActionType = ActiveControl.DRAW_RECTANGLE | ActiveControl.DRAW_POLYLINE | ActiveControl.DRAW_POLYGON | ActiveControl.DRAW_POINTS

// 6 ActiveControl.DRAW_RECTANGLE
// 10 ActiveControl.DRAW_POLYGON
// 13 ActiveControl.DRAW_POLYLINE
// 7 ActiveControl.DRAW_POINTS
export const onboardingActionDraw: IAction = {
    6: {
        step: StepsTarget.STEP_2,
        length: 3
    },
    10: {
        step: StepsTarget.STEP_9,
        length: 1
    },
    14: {
        step: StepsTarget.STEP_13,
        length: 1
    },
    18: {
        step: StepsTarget.STEP_17,
        length: 1
    }
}