import React, { memo } from 'react'
import CheckOnboarding from 'components/onboarding/check-onboarding';
import Onboarding from 'components/onboarding/onboarding';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';

const OnboardingContainer = () => {
    const { onboarding } = useSelector((state: CombinedState) => state.auth.user)

    return (
        <>
            {onboarding === false &&
                <>
                    <CheckOnboarding />
                    <Onboarding />
                </>
            }
        </>
    )
}


export default memo(OnboardingContainer)