import React, { useState } from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { Project } from 'reducers/interfaces';

interface IProps {
    project: Project;
    onChange: (link: string) => void;
}

const DiscussLink = ({ project, onChange }: IProps) => {

    const [link, setLink] = useState<string>(() => project.discussion_link || '');

    const onChangeValue = (value: string): void => {
        setLink(value);
        onChange(value)
    };

    return (
        <Row className='cvat-issue-tracker' style={{ marginTop: 20 }}>
            <Col>
                <Text strong className='cvat-text-color'>Discord</Text>
                <br />
                <Text
                    editable={{
                        onChange: onChangeValue,
                    }}
                >
                    {link}
                </Text>
            </Col>
        </Row>
    )
}

export default DiscussLink