// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { CombinedState } from '../../reducers/interfaces';
import { deleteUser, editUsersAdminStatus, sendInvite } from '../../actions/users-actions';

interface UserItemProps {
    user: any;
    deleteUserFromList: any;
}

interface StateToProps {
    currentUser: any;
}

interface DispatchToProps {
    deleteUserItem(userID: number): void;
    editUsersAdminStatus(userID: number, shouldBeAdmin: boolean): void;
    sendInvite(emails: string[]): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        currentUser: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        deleteUserItem: (userID: number): void => {
            dispatch(deleteUser(userID));
        },
        editUsersAdminStatus: (userID: number, shouldBeAdmin: boolean): void => {
            dispatch(editUsersAdminStatus(userID, shouldBeAdmin));
        },
        sendInvite: (emails: string[]): void => {
            dispatch(sendInvite(emails));
        },
    };
}

type Props = UserItemProps & DispatchToProps & StateToProps;

class UserItemComponent extends React.PureComponent<Props> {
    public render() {
        const { currentUser, deleteUserFromList, user, deleteUserItem } = this.props;

        let result;
        if (user.is === 'invitation') {
            result = (
                <Row className='cvat-user-list-item invitation-item'>
                    <Col span={7} style={{ marginLeft: '20px' }}>
                        <Text strong>Invitation Email</Text>
                        <br />
                        <Text>{user.email}</Text>
                    </Col>
                    <Col span={7}>
                        <Text strong>Created</Text>
                        <br />
                        <Text>{moment(user.created).format('YYYY-MM-DD')}</Text>
                    </Col>
                    <Col span={5}>
                        <Text strong>Expired</Text>
                        <br />
                        <Text>{moment(user.expired).format('YYYY-MM-DD')}</Text>
                    </Col>
                    <Col span={4}>
                        <Button
                            onClick={() => {
                                sendInvite([user.email]);
                            }}
                        >
                            Resend
                        </Button>
                    </Col>
                </Row>
            );
        } else {
            const { firstName, lastName, username } = user;
            const usernameComponent = <Text strong className='cvat-text-color'>{username}</Text>;
            const showFullName = firstName || lastName;
            result = (
                <Row key={`usrlist_${user.id}`} className='cvat-user-list-item'>
                    <Col span={7} style={{ marginLeft: '20px' }}>
                        <Row>
                            <Text strong type='secondary' style={{ marginRight: '8px' }}>
                                {`#${user.id}`}
                                {/* {`#${user.listID}`} */}
                            </Text>
                            {showFullName ? (
                                <Text strong className='cvat-text-color'>
                                    {`${firstName || ''} ${lastName || ''}`}
                                </Text>
                            ) : (
                                usernameComponent
                            )}
                        </Row>
                        {showFullName && <Row>{usernameComponent}</Row>}
                        <Row>
                            <Text strong className='cvat-text-color'>
                                {user.email}
                            </Text>
                        </Row>
                    </Col>
                    <Col span={7} />
                    <Col span={4} className='action-buttons' />
                    <Col span={5} className='action-buttons'>
                        {currentUser.id !== user.id ? (
                            <Row>
                                <Button
                                    onClick={() => {
                                        deleteUserItem(user.id);
                                        deleteUserFromList(user.id);
                                    }}
                                >
                                    Remove
                                </Button>
                            </Row>
                        ) : null}
                    </Col>
                </Row>
            );
        }

        return result;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserItemComponent);
