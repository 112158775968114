import React, { useEffect, useState } from 'react'
import { Modal, Select, Form, Input, Button, Row, Col, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import getCore from '../../cvat-core-wrapper';

const core = getCore();

const { Option } = Select;

interface IUser {
    id: number,
    username: string
}

interface Props {
    visible: boolean,
    closeModal: () => void,
    onSubmitForm: (values: any) => void,
    projectInstance: any
}

const EditProjectContent: React.FC<Props> = ({ visible, closeModal, onSubmitForm, projectInstance }): JSX.Element => {

    const [usersList, setUsersList] = useState<IUser[]>([])
    const [fetching, setFetching] = useState<boolean>(false);
    const { current } = useSelector((state: CombinedState) => state.projects)
    const { users } = useSelector((state: CombinedState) => state.users)

    const params = {
        showSearch: true,
        // mode: "multiple",
        filterOption: (input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    useEffect(() => {
        let list = users.map((el: any) => ({ id: el.id, username: el.username }));
        let projectListAnnotators = projectInstance.annotators.map((el: any) => ({ id: el.id, username: el.username }));
        let unique = [...new Map([...list, ...projectListAnnotators].map((item: any) => [item['id'], item])).values()];

        let arr = [...unique];
        setUsersList(arr)
    }, [])

    function onSearchUser(e: string) {
        if (e.length > 1) {
            setFetching(true);
            core.users.get({
                search: e,
                limit: 10,
            }).then((result: any) => {
                let list = result.users.map((el: any) => ({ id: el.id, username: el.username }))
                setUsersList(list)
                setFetching(false);
            })
        }
    }

    return (
        <Modal
            title="Edit Project"
            visible={visible}
            onCancel={closeModal}
            footer={null}
            destroyOnClose={true}
        >
            <Form
                name="edit"
                layout="horizontal"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                onFinish={onSubmitForm}
            >
                <Form.Item initialValue={current[0].name} label="Name" name="name">
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Annotators" name="annotators" initialValue={projectInstance.annotators.map((el: IUser) => el.id)}>
                    <Select
                        mode="multiple"
                        placeholder="Annotators"
                        {...params}
                        onSearch={onSearchUser}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                    >
                        {usersList?.map(({ id, username }: IUser): any => <Option key={id} value={id}>{username}</Option>)}
                    </Select>
                </Form.Item>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button key="submit" type="primary" htmlType="submit">Save</Button>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
}

export default React.memo(EditProjectContent)
