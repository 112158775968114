// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
    Col,
    Row,
    Button,
    Input,
} from 'antd';

import Text from 'antd/lib/typography/Text';
import { CombinedState, TasksQuery } from "../../reducers/interfaces";
import FilterModal from "./filter-modal-component";
import { connect } from "react-redux";
// import {isPermissible, PermissionTypes, UserRole} from "../../settings";
import { getNewTask } from "../../actions/tasks-actions";
import ChooseProjectModal from "./choose-project-modal";

interface VisibleTopBarProps {
    searchValue: string;
    gettingQuery: TasksQuery;
    updateURL: any;
}

interface ComponentState {
    modalType: string | null;
}

interface StateToProps {
    // userGroups: UserRole[];
    userGroups: any[];
}

type Props = VisibleTopBarProps & StateToProps & DispatchToProps & RouteComponentProps;

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        auth: {
            user: {
                groups: userGroups,
            }
        },
    } = state;

    return {
        userGroups,
    }
}

interface DispatchToProps {
    askForNewTask(projectID: number): void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        askForNewTask(projectID: number): void {
            dispatch(getNewTask(projectID));
        },
    }
}

class TopBarComponent extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalType: null,
        };
    }
    private handleSearch(value: string): void {
        const {
            gettingQuery,
        } = this.props;

        const query = { ...gettingQuery };
        const search = value.replace(/\s+/g, ' ')
            .replace(/\s*:+\s*/g, ':').trim();

        const fields = ['name', 'mode', 'owner', 'assignee', 'status', 'id', 'project', 'type'];
        for (const field of fields) {
            query[field] = null;
        }
        query.search = null;

        let specificRequest = false;
        for (const param of search.split(/[\s]+and[\s]+|[\s]+AND[\s]+/)) {
            if (param.includes(':')) {
                const [field, fieldValue] = param.split(':');
                if (fields.includes(field) && !!fieldValue) {
                    specificRequest = true;
                    if (field === 'id') {
                        if (Number.isInteger(+fieldValue)) {
                            query[field] = +fieldValue;
                        }
                    } else {
                        query[field] = fieldValue;
                    }
                }
            }
        }

        query.page = 1;
        if (!specificRequest && value) { // only id
            query.search = value;
        }

        this.props.updateURL(query);
    };



    render() {
        const {
            askForNewTask,
            userGroups,
        } = this.props;

        const closeModal = () => {
            this.setState({ modalType: null });
        };

        const finishModalChooseProject = (id: number) => {
            askForNewTask(id)
            this.setState({ modalType: null });
        }

        return (
            <>
                <Row justify='center' align='middle'>
                    <Col md={11} lg={9} xl={8} xxl={7}>
                        {/* <Text className='cvat-title'>Tasks</Text>
                        <Input.Search
                            defaultValue={this.props.searchValue}
                            onSearch={this.handleSearch}
                            size='large'
                            placeholder='Search'
                        />
                        <Button
                            size='large'
                            style={{
                                marginLeft: '10px',
                            }}
                            onClick={() => this.setState({modalType: 'filter'})}>
                            Filter
                        </Button> */}
                    </Col>
                    <Col
                        md={{ span: 11 }}
                        lg={{ span: 9 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 7 }}
                    >
                        {/* {
                            <Button
                                size='large'
                                style={{ marginRight: '10px' }}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        modalType: 'choose-project'
                                    })
                                }}
                            >
                                Ask for a new task
                            </Button>
                        } */}
                        {/* {
                            <Button
                                size='large'
                                id='cvat-create-task-button'
                                type='primary'
                                onClick={(): void => this.props.history.push('/tasks/create')}
                            >
                                Create new task
                            </Button>
                        } */}
                    </Col>
                </Row>
                {/* <FilterModal
                    visible={this.state.modalType === 'filter'}
                    closeModal={closeModal}
                    updateURL={this.props.updateURL}
                />
                <ChooseProjectModal
                    visible={this.state.modalType === 'choose-project' }
                    onCancel={closeModal}
                    onSubmit={finishModalChooseProject}
                /> */}
            </>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopBarComponent));
