import React from 'react';
import './styles.scss';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { CongratulationIcon } from 'icons';

const Congratulation: React.FC = (): JSX.Element => {

    const LINK: string = process.env.REACT_APP_HOSTNAME_PLATFORM_ANNOTATOR;

    return (
        <div className="cvat-congratulation-page">
            <Icon className='cvat-congratulation-page_icon' component={CongratulationIcon} />
            <h1>Congratulations!</h1>
            <p>You have successfully submitted your task.</p>
            <p>The reward will be added to your account after revision of the task. This task could be returned for the correction, if there are some issues with the labelling. Go to the Available task page to select the next task.</p>
            <Button size="large" type="primary">
                <a href={`${LINK}/jobs/available`}>Available tasks</a>
            </Button>
        </div>
    )
}

export default Congratulation
