// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import { AuthActions, AuthActionTypes } from 'actions/auth-actions';
import { UsersActions, UsersActionTypes } from 'actions/users-actions';
import { UsersState } from './interfaces';

const defaultState: UsersState = {
    users: [],
    invitations: [],
    justSentInvitations: [],
    fetching: false,
    initialized: false,
    count: null,
    next: null
};

export default function (state: UsersState = defaultState, action: UsersActions | AuthActions): UsersState {
    switch (action.type) {
        case UsersActionTypes.GET_USERS: {
            return {
                ...state,
                fetching: true,
                initialized: false,
            };
        }
        case UsersActionTypes.GET_USERS_SUCCESS:
            let { count, users, next } = action.payload

            return {
                ...state,
                fetching: false,
                initialized: true,
                users: users,
                count: count,
                next: next
            };
        case UsersActionTypes.GET_USERS_FAILED:
            return {
                ...state,
                fetching: false,
                initialized: true,
            };
        case UsersActionTypes.GET_INVITES_SUCCESS: {
            return {
                ...state,
                invitations: action.payload.invitations,
            };
        }
        case UsersActionTypes.EDIT_USER: {
            const { updatedUser } = action.payload;
            const { users } = state;
            return {
                ...state,
                users: users.map((usr) => {
                    if (usr.id === updatedUser.id) {
                        return updatedUser;
                    }
                    return usr;
                }),
            };
        }
        /* Just for updating ui while requests are being made */
        case UsersActionTypes.EDIT_USER_ADMIN: {
            const { users } = state;
            const { userID, shouldBeAdmin } = action.payload;
            for (const usr of users) {
                if (usr.id === userID) {
                    if (shouldBeAdmin) {
                        if (!usr.groups.includes('admin')) {
                            usr.groups = [...usr.groups, 'admin'];
                        }
                    } else if (usr.groups.includes('admin')) {
                        usr.groups = usr.groups.filter((group: string) => group !== 'admin');
                    }
                }
            }
            return {
                ...state,
                users,
            };
        }

        case UsersActionTypes.SEND_INVITE: {
            return {
                ...state,
                justSentInvitations: [],
            };
        }

        case UsersActionTypes.SEND_INVITE_SUCCESS: {
            return {
                ...state,
                justSentInvitations: action.payload.results,
            };
        }

        case AuthActionTypes.LOGOUT_SUCCESS: {
            return {
                ...defaultState,
            };
        }
        case UsersActionTypes.DELETE_USER_SUCCESS: {
            let { id } = action.payload
            return {
                ...state,
                users: state.users.filter(el => el.id !== id)
            };
        }
        default:
            return state;
    }
}
