// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import './login-form.scss';

export interface LoginData {
    username: string;
    password: string;
}

interface Props {
    fetching: boolean;
    onSubmit(loginData: LoginData): void;
}

function LoginFormComponent(props: Props): JSX.Element {
    const { fetching, onSubmit } = props;
    return (
        <Form onFinish={onSubmit} className='login-form'>
            <div className='username-field'>
                <span>Login</span>
                <Form.Item
                    hasFeedback
                    name='username'
                    rules={[
                        {
                            required: true,
                            message: 'Please specify a username',
                        },
                    ]}
                >
                    <Input autoComplete='username' spellCheck={false} />
                </Form.Item>
            </div>

            <div className='password-field'>
                <span>Password</span>
                <Form.Item
                    hasFeedback
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please specify a password',
                        },
                    ]}
                >
                    <Input autoComplete='current-password' type='password' />
                </Form.Item>
            </div>

            <Form.Item>
                <Button
                    type='primary'
                    loading={fetching}
                    disabled={fetching}
                    htmlType='submit'
                    className='login-form-button'
                >
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    );
}

export default React.memo(LoginFormComponent);
