import { ActionUnion, createAction } from 'utils/redux';

export enum OnboardingActionTypes {
    SET_RUN = 'SET_RUN',
    SET_CURRENT_INDEX = 'SET_CURRENT_INDEX',
    SET_STEPS = 'SET_STEPS',
    SET_CURRENT_INDEX_INCREMENT = 'SET_CURRENT_INDEX_INCREMENT',
    FINISH_ONBOARDING = 'FINISH_ONBOARDING',
    SET_ENABLED = 'SET_ENABLED',
    SET_VISIBLE_MENU = 'SET_VISIBLE_MENU'
}

// const store = getCVATStore();
// const state: CombinedState = store.getState();

export const onboardingActions = {
    setRun: (value: boolean) => createAction(OnboardingActionTypes.SET_RUN, value),
    setCurrentIndex: (index: number) => createAction(OnboardingActionTypes.SET_CURRENT_INDEX, index),
    setSteps: (steps: any[]) => createAction(OnboardingActionTypes.SET_STEPS, steps),
    // setCurrentIndexIncrement: () => createAction(OnboardingActionTypes.SET_CURRENT_INDEX_INCREMENT),
    setFinishOnboarding: (value: boolean) => createAction(OnboardingActionTypes.FINISH_ONBOARDING, value),
    setEnabled: (value: boolean) => createAction(OnboardingActionTypes.SET_ENABLED, value),
    setVisibleMenu: (value: boolean) => createAction(OnboardingActionTypes.SET_VISIBLE_MENU, value),
    setCurrentIndexIncrement: () => {
        return (dispatch: any, getState: any) => {
            let state = getState();
            let onboarding = state.auth.user.onboarding;
            
            if (onboarding === false) {
                dispatch({
                    type: OnboardingActionTypes.SET_CURRENT_INDEX_INCREMENT,
                })
                return
            }
        }
    },
};

export type OnboardingActions = ActionUnion<typeof onboardingActions>;