// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon from '@ant-design/icons';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import { useSelector } from 'react-redux';

import { Workspace, CombinedState } from 'reducers/interfaces';
import { InfoIcon, FullscreenIcon, FilterIcon } from 'icons';

interface Props {
    workspace: Workspace;
    showStatistics(): void;
    showFilters(): void;
    changeWorkspace(workspace: Workspace): void;
    showWorkspaceMode: boolean;
}

function RightGroup(props: Props): JSX.Element {
    const {
        showStatistics, changeWorkspace, workspace, showFilters, showWorkspaceMode = true
    } = props;

    const filters = useSelector((state: CombinedState) => state.annotation.annotations.filters);

    return (
        <Col className='cvat-annotation-header-right-group'>
            <Button
                type='link'
                className='cvat-annotation-header-button'
                onClick={(): void => {
                    if (window.document.fullscreenEnabled) {
                        if (window.document.fullscreenElement) {
                            window.document.exitFullscreen();
                        } else {
                            window.document.documentElement.requestFullscreen();
                        }
                    }
                }}
            >
                <Icon component={FullscreenIcon} />
                Fullscreen
            </Button>
            <Button type='link' className='cvat-annotation-header-button' onClick={showStatistics}>
                <Icon component={InfoIcon} />
                Info
            </Button>
            <Button
                type='link'
                className={`cvat-annotation-header-button ${filters.length ? 'filters-armed' : ''}`}
                onClick={showFilters}
            >
                <Icon component={FilterIcon} />
                Filters
            </Button>
            {showWorkspaceMode && <div>
                <Select
                    dropdownClassName='cvat-workspace-selector-dropdown'
                    className='cvat-workspace-selector'
                    onChange={changeWorkspace}
                    value={workspace}
                >
                    {Object.values(Workspace).map((ws) => (
                        <Select.Option key={ws} value={ws}>
                            {ws}
                        </Select.Option>
                    ))}
                </Select>
            </div>}
        </Col>
    );
}

export default React.memo(RightGroup);
