// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { CheckSquareOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import { resolveAllIssues } from 'actions/review-actions';
import { useDispatch } from 'react-redux';

function ResolveIssues(): JSX.Element {

    const dispatch = useDispatch()

    return (
        <Tooltip title='Resolve all issues' placement='right' mouseLeaveDelay={0}>
            <CheckSquareOutlined
                className='cvat-issue-control'
                onClick={(): void => {
                    dispatch(resolveAllIssues())
                }}
            />
        </Tooltip>
    );
}

export default React.memo(ResolveIssues);
