// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';

import { Canvas } from 'cvat-canvas-wrapper';
import { PolylineIcon } from 'icons';
import { ShapeType } from 'reducers/interfaces';

import DrawShapePopoverContainer from 'containers/annotation-page/standard-workspace/controls-side-bar/draw-shape-popover';
import withVisibilityHandling from './handle-popover-visibility';
import BlockControl from './block-control';

interface Props {
    canvasInstance: Canvas;
    isDrawing: boolean;
    setOnboardingStep(): void;
    active: boolean;
}

function DrawPolylineControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, setOnboardingStep, active } = props;
    const CustomPopover = withVisibilityHandling(Popover, 'draw-polyline');

    const dynamcPopoverPros = isDrawing ?
        {
            overlayStyle: {
                display: 'none',
            },
        } :
        {};

    const dynamicIconProps = isDrawing ?
        {
            className: 'cvat-draw-polyline-control cvat-active-canvas-control step-13',
            onClick: (): void => {
                canvasInstance.draw({ enabled: false });
            },
        } :
        {
            className: 'cvat-draw-polyline-control step-13',
        };

    return (
        <>
            {
                active
                    ? <CustomPopover
                        {...dynamcPopoverPros}
                        overlayClassName='cvat-draw-shape-popover'
                        placement='right'
                        content={<DrawShapePopoverContainer shapeType={ShapeType.POLYLINE} />}
                    >
                        <Icon onClick={() => setOnboardingStep()} {...dynamicIconProps} component={PolylineIcon} />
                    </CustomPopover>
                    : <BlockControl
                        icon={PolylineIcon}
                        dynamicIconProps={dynamicIconProps}
                    />
            }
        </>

    );
}

export default React.memo(DrawPolylineControl);
