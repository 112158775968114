import mixpanel from "mixpanel-browser";
import { MIXPANEL_TRACK_KEYS } from "./mixpanel-keys";
import { ROLES } from "reducers/interfaces";


export class Mixpanel {
    private static tracking_active: boolean = window.location.origin === "https://cv.remoter.me";

    private static user: any = {} as any;

    static init(): void {
        if (!Mixpanel.tracking_active) return;
        const KEY = process.env.REACT_APP_MIXPANEL_KEY;

        if (!KEY) {
            console.error("Mixpanel key is missing");
            return;
        }

        mixpanel.init(KEY, {
            debug: false,
            track_pageview: false,
            persistence: "localStorage"
        });
    }

    static set_user(user: any): void {
        if (!Mixpanel.tracking_active) return;

        Mixpanel.user = user;

        if (!user?.groups.includes(ROLES.ANNOTATOR)) return;


        Mixpanel.init()
        mixpanel.identify(Mixpanel.user.username);

        mixpanel.people.set({
            $name: Mixpanel.user.username,
            $email: Mixpanel.user.email,
        });

        mixpanel.register({
            user_id: Mixpanel.user.id
        });

    }

    static track(track_key: MIXPANEL_TRACK_KEYS, props: any): void {
        if (!Mixpanel.tracking_active) return;

        if (!Mixpanel.user.groups.includes(ROLES.ANNOTATOR)) return;

        mixpanel.track(track_key, props);
    }

    static reset(): void {
        if (!Mixpanel.tracking_active) return;

        mixpanel.reset();
        Mixpanel.user = {};
    }
}