// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Spin from 'antd/lib/spin';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import { Modal, Form, Select, Tag, Dropdown, message } from 'antd';
import Menu from 'antd/lib/menu';
import Text from 'antd/lib/typography/Text';
import {
    SortAscendingOutlined, PlusOutlined, StopOutlined, FilterOutlined, FilterTwoTone, CheckSquareOutlined, DownloadOutlined
} from '@ant-design/icons';

import { cancelInferenceAsync } from 'actions/models-actions';
import TaskItem from 'components/tasks-page/task-item';
import { CombinedState, FILDS_NAME_FOR_LOCALSTORAGE, Task } from 'reducers/interfaces';
import DetailsComponent from './details';
import ProjectTopBar from './top-bar';
import { getProjectByIDAsync } from '../../actions/projects-actions';
import { bulkAnnotationsAsync } from '../../actions/tasks-actions';

const { Option } = Select;

const SELECTED_ELEMENTS = 20;

interface ParamType {
    id: string;
}

export default function ProjectPageComponent(): JSX.Element {
    const id = +useParams<ParamType>().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const projects = useSelector((state: CombinedState) => state.projects.current);
    const projectsFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const deletes = useSelector((state: CombinedState) => state.projects.activities.deletes);
    const taskDeletes = useSelector((state: CombinedState) => state.tasks.activities.deletes);
    const tasksActiveInferences = useSelector((state: CombinedState) => state.models.inferences);
    const tasks = useSelector((state: CombinedState) => state.tasks.current);

    const filteredProjects = projects.filter((project) => project.id === id);
    const project = filteredProjects[0];
    const deleteActivity = project && id in deletes ? deletes[id] : null;
    const [data, setData] = React.useState(tasks);
    const sortedRef: any = React.useRef({ name: true, status: true });
    const [isFilterModalVisible, setIsFilterModalVisible] = React.useState(false);
    const [assignedUsers, setAssignedUsers] = React.useState<string[]>([]);
    const [statuses, setStatuses] = React.useState<string[]>([]);
    const [filterValues, setFilterValues] = React.useState({
        assignedUser: '',
        reviewerUser: '',
        status: '',
        dumped_annotation: 'any'
    });

    const [selectedTasks, setSelectedTasks] = React.useState<{ id: number, name: string }[]>([]);
    const [selectMode, setSelectMode] = React.useState<boolean>(false);
    const { dumpers } = useSelector((state: CombinedState) => state.formats.annotationFormats)
    const { bulk_annotations_loading } = useSelector((state: CombinedState) => state.tasks)
    const [reviewerUsers, setReviewerUsers] = React.useState<string[]>([]);

    useEffect(() => {
        dispatch(getProjectByIDAsync(id));
    }, [id, dispatch]);

    useEffect(() => {
        let json: any = localStorage.getItem(`${FILDS_NAME_FOR_LOCALSTORAGE.PROJECT}-${project?.id}`)
        let filters = JSON.parse(json);

        if (project?.id && filters) {
            setFilterValues(prev => filters);
        }

    }, [project?.id])

    useEffect(() => {
        if (tasks.length) {
            setData(tasks);
            const temp: any[] = [];
            const reviewers: any[] = []
            tasks.forEach((it: any) => {
                it.instance.jobs.forEach((job: any) => !!job.assignee && temp.push(job.assignee.username))
                it.instance.jobs.forEach((job: any) => !!job.reviewer && reviewers.push(job.reviewer.username))
            })
            setAssignedUsers([...new Set(temp)].sort());
            setReviewerUsers([...new Set(reviewers)].sort());
            setStatuses([...new Set(tasks.map((it: any) => it.instance.status))]);
        }
    }, [tasks]);
    
    if (deleteActivity) {
        history.push('/projects');
    }

    if (projectsFetching) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (!project) {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title='Sorry, but this project was not found'
                subTitle='Please, be sure information you tried to get exist and you have access'
            />
        );
    }

    const sort = (type: string): void => {
        const isSorted = sortedRef.current[type];
        const direction = isSorted ? 1 : -1;

        const sorted = [].slice.call(data).sort((a: any, b: any) => {
            if (a.instance[type] === b.instance[type]) {
                return 0;
            }
            return a.instance[type] > b.instance[type] ? direction : direction * -1;
        });

        sortedRef.current[type] = !isSorted;
        setData(sorted);
    };

    const renderOption = (val: string, idx: number): JSX.Element => (
        <Option key={`${val}-${idx}`} value={val}>
            {val}
        </Option>
    );

    const hasTaskAssignedUserInJobs = (task: any): boolean =>
        task.jobs.some((it: any) => {
            if (it.assignee) {
                return it.assignee.username === filterValues.assignedUser;
            }
            return false;
        });

    function onOKModal() {
        setIsFilterModalVisible(false);
        let json = JSON.stringify(filterValues);
        localStorage.setItem(`${FILDS_NAME_FOR_LOCALSTORAGE.PROJECT}-${project?.id}`, json);
    }

    function onReset() {
        setData(tasks);
        setFilterValues({
            assignedUser: '',
            reviewerUser: '',
            status: '',
        });
        localStorage.removeItem(`${FILDS_NAME_FOR_LOCALSTORAGE.PROJECT}-${project?.id}`);
        setSelectMode(false);
        setSelectedTasks([])
    }

    function onSelect(): void {
        setSelectMode(!selectMode);
        setSelectedTasks([])
    }

    function onSelectAll() {
        setSelectMode(true);
        let all_data = filters(data)
            .filter((_, index) => index < SELECTED_ELEMENTS)
            .map((task: any) => ({ id: task.instance.id, name: task.instance.name }));
        setSelectedTasks(all_data)
    }


    function onChangeChecked(task: any): void {
        let { id, name } = task.instance;
        let find_task = selectedTasks.find((el: any) => el.id === id);
        if (Boolean(find_task)) {
            let filter = selectedTasks.filter((el: any) => el.id !== id);
            setSelectedTasks(filter)
        } else {
            if (selectedTasks.length === SELECTED_ELEMENTS) {
                message.warning(`No more than ${SELECTED_ELEMENTS} at a time`);
                return;
            }
            setSelectedTasks([...selectedTasks, { id, name }])
        }
    }

    function checkSelectedTask(id: number): boolean {
        let task = selectedTasks.find((el: any) => el.id === id);
        return Boolean(task)
    }

    const dumpSubMenu = dumpers
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .map(
            (dumper: any) => <Menu.Item key={dumper.name}>
                <DownloadOutlined />
                <Text>{dumper.name}</Text>
            </Menu.Item>
        )

    function onClickMenuWrapper(info: any, type: 'annotations' | 'dataset'): void {
        let { key } = info;
        let data: any = {
            format: key,
        };

        data.tasks = selectedTasks.map((el: any) => el.id)

        dispatch(bulkAnnotationsAsync(data)).then((res) => {
            setSelectMode(false);
            setSelectedTasks([])
        })
    }

    function filters(array: any): Task[] {
        let new_array = array
            .filter((task) => task.instance.projectId === project.id)
            .filter((task: Task) => {
                if (filterValues.reviewerUser === 'unassigned') {
                    return task.instance.jobs.some((it: any) => {
                        if (it.reviewer === null) {
                            return true
                        }
                        return false;
                    });
                }
                if (filterValues.reviewerUser) {
                    return task.instance.jobs.some((it: any) => {
                        if (it.reviewer) {
                            return it.reviewer.username === filterValues.reviewerUser;
                        }
                        return false;
                    });
                }
                return true;
            })
            .filter((task: Task) => {
                if (filterValues.assignedUser === 'unassigned') {
                    return task.instance.jobs.some((it: any) => {
                        if (it.assignee === null) {
                            return true
                        }
                        return false;
                    });
                }
                if (filterValues.assignedUser) {
                    return hasTaskAssignedUserInJobs(task.instance);
                }
                return true;
            })
            .filter((task) => {
                if (filterValues.status) {
                    return task.instance.status === filterValues.status;
                }
                return true;
            })
            .filter(task => {
                if (filterValues.dumped_annotation === 'true') {
                    return task.instance.dumped_annotation === true
                }
                if (filterValues.dumped_annotation === 'false') {
                    return task.instance.dumped_annotation === false
                }
                return task
            })

        return new_array
    }

    return (
        <Row justify='center' align='top' className='cvat-project-page'>
            <Col md={22} lg={18} xl={16} xxl={14}>
                <ProjectTopBar projectInstance={project} />
                <DetailsComponent project={project} />
                <Row justify='space-between' align='middle' className='cvat-project-page-tasks-bar'>
                    <Col>
                        <Title level={4} style={{ display: 'inline-block', marginRight: '20px' }}>
                            Tasks
                        </Title>
                        {tasks ? (
                            <>
                                <Button
                                    onClick={() => sort('id')}
                                    icon={<SortAscendingOutlined />}
                                    style={{ marginRight: '5px' }}
                                >
                                    Sort by date
                                </Button>
                                <Button
                                    icon={<CheckSquareOutlined />}
                                    style={{ marginRight: '5px' }}
                                    onClick={onSelect}
                                    disabled={bulk_annotations_loading}
                                >
                                    {selectedTasks.length === 0 ? `Select ` : `Unselect (${selectedTasks.length}/${SELECTED_ELEMENTS})`}
                                </Button>
                                <Button
                                    icon={<CheckSquareOutlined />}
                                    style={{ marginRight: '5px' }}
                                    onClick={onSelectAll}
                                    disabled={bulk_annotations_loading}
                                >
                                    Select batch({SELECTED_ELEMENTS})
                                </Button>
                                {
                                    selectedTasks.length > 0 && <>
                                        <Dropdown disabled={bulk_annotations_loading} overlay={<Menu onClick={(e: any) => onClickMenuWrapper(e, 'annotations')}>{dumpSubMenu}</Menu>}>
                                            <Button disabled={bulk_annotations_loading} style={{ marginRight: '5px' }}>
                                                Dump annotations
                                            </Button>
                                        </Dropdown>
                                        {/* <Dropdown overlay={<Menu onClick={(e: any) => onClickMenuWrapper(e, 'dataset')}>{dumpSubMenu}</Menu>}>
                                            <Button style={{ marginRight: '5px' }} >
                                                Export as a dataset
                                            </Button>
                                        </Dropdown> */}
                                    </>
                                }
                                <Button
                                    onClick={onReset}
                                    icon={<StopOutlined />}
                                    style={{ marginRight: '5px' }}
                                    danger
                                >
                                    Reset
                                </Button>
                                <Button
                                    onClick={() => setIsFilterModalVisible(true)}
                                    icon={Boolean(localStorage.getItem(`${FILDS_NAME_FOR_LOCALSTORAGE.PROJECT}-${project?.id}`)) ? <FilterTwoTone /> : <FilterOutlined />}
                                />
                            </>
                        ) : null}
                    </Col>
                    <Col>
                        <Button
                            size='large'
                            type='primary'
                            icon={<PlusOutlined />}
                            id='cvat-create-task-button'
                            onClick={() => history.push(`/tasks/create?projectId=${id}`)}
                        >
                            Create new task
                        </Button>
                    </Col>
                </Row>
                {
                    selectedTasks.length > 0 && <div className="wrap_selected">
                        {
                            selectedTasks.map((el: any) => {
                                return <Tag style={{ marginBottom: 10 }}># {el.id} - {el.name}</Tag>
                            })
                        }
                    </div>
                }
                {
                    filters(data).map((task: Task) => (
                        <TaskItem
                            key={task.instance.id}
                            deleted={task.instance.id in taskDeletes ? taskDeletes[task.instance.id] : false}
                            hidden={false}
                            activeInference={tasksActiveInferences[task.instance.id] || null}
                            cancelAutoAnnotation={() => {
                                dispatch(cancelInferenceAsync(task.instance.id));
                            }}
                            previewImage={task.preview}
                            taskInstance={task.instance}
                            selectMode={selectMode}
                            onChangeChecked={() => onChangeChecked(task)}
                            selected={checkSelectedTask(task.instance.id)}
                        />
                    ))
                }
            </Col >
            <Modal
                title='Advanced filtering'
                onCancel={() => setIsFilterModalVisible(false)}
                onOk={onOKModal}
                closable
                visible={isFilterModalVisible}
                okText="Save"
            >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    {assignedUsers.length ? (
                        <Form.Item label='assignee:'>
                            <Select
                                onChange={(assignedUser) => setFilterValues({ ...filterValues, assignedUser })}
                                defaultValue='any'
                                placeholder='please select'
                                value={filterValues.assignedUser}
                            >
                                <Option value=''>- any -</Option>
                                <Option value='unassigned'>-- unassigned --</Option>
                                {assignedUsers.map(renderOption)}
                            </Select>
                        </Form.Item>
                    ) : null}
                    {reviewerUsers.length ? (
                        <Form.Item label='reviewer:'>
                            <Select
                                onChange={(reviewerUser: any) => setFilterValues({ ...filterValues, reviewerUser })}
                                defaultValue='any'
                                placeholder='please select'
                                value={filterValues.reviewerUser}
                            >
                                <Option value=''>- any -</Option>
                                <Option value='unassigned'>unassigned</Option>
                                {reviewerUsers.map(renderOption)}
                            </Select>
                        </Form.Item>
                    ) : null}
                    {statuses.length && (
                        <Form.Item label='status:'>
                            <Select
                                onChange={(status) => setFilterValues({ ...filterValues, status })}
                                defaultValue='any'
                                placeholder='please select'
                                value={filterValues.status}
                            >
                                <Option value=''>- any -</Option>
                                {statuses.includes('annotation') && <Option value='annotation'>annotation</Option>}
                                {statuses.includes('completed') && <Option value='completed'>completed</Option>}
                                {statuses.includes('validation') && <Option value='validation'>validation</Option>}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item label='dump:'>
                        <Select
                            onChange={(value: string) => setFilterValues({ ...filterValues, dumped_annotation: value })}
                            defaultValue='any'
                            placeholder='please select'
                            value={filterValues.dumped_annotation}
                        >
                            <Option value='any'>- any -</Option>
                            <Option value='false'>False</Option>
                            <Option value='true'>True</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </Row >
    );
}
