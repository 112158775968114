// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import React from 'react';
import { connect } from 'react-redux';

import { CombinedState } from 'reducers/interfaces';

import UsersPageComponent from 'components/users-page/users-page-component';
import { getUsersAsync } from '../../actions/users-actions';

interface StateToProps {
    usersFetching: boolean;
    users: any[];
    next: string | null;
    count: number | null
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { users } = state;
    return {
        users: users.users,
        count: users.count,
        next: users.next,
        usersFetching: users.fetching,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getUsersList(page: number): void {
            dispatch(getUsersAsync(page));
        },
    };
}

type TasksPageContainerProps = StateToProps;

function UsersPageContainer(props: TasksPageContainerProps): JSX.Element {
    return <UsersPageComponent {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPageContainer);
