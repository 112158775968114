// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import DumpSubmenu from './dump-submenu';
import LoadSubmenu from './load-submenu';
import ExportSubmenu from './export-submenu';
import { CombinedState, DimensionType } from '../../reducers/interfaces';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getInfoCountTaskAsync } from 'actions/tasks-actions';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
    taskID: number;
    taskMode: string;
    bugTracker: string;
    loaders: any[];
    dumpers: any[];
    loadActivity: string | null;
    dumpActivities: string[] | null;
    exportActivities: string[] | null;
    inferenceIsActive: boolean;
    taskDimension: DimensionType;
    onClickMenu: (params: MenuInfo, file?: File) => void;
    client?: boolean
}

export enum Actions {
    DUMP_TASK_ANNO = 'dump_task_anno',
    LOAD_TASK_ANNO = 'load_task_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    DELETE_TASK = 'delete_task',
    RUN_AUTO_ANNOTATION = 'run_auto_annotation',
    OPEN_BUG_TRACKER = 'open_bug_tracker',
}

export default function ActionsMenuComponent(props: Props): JSX.Element {
    const {
        taskID,
        taskMode,
        bugTracker,
        inferenceIsActive,
        dumpers,
        loaders,
        onClickMenu,
        dumpActivities,
        exportActivities,
        loadActivity,
        taskDimension,
        client
    } = props;


    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { infoCounts } = useSelector((state: CombinedState) => state.tasks);

    const onInfoProject = useCallback((): void => {
        dispatch(getInfoCountTaskAsync(taskID))
        setIsModalVisible(true)
    }, [taskID])

    let latestParams: MenuInfo | null = null;
    function onClickMenuWrapper(params: MenuInfo | null, file?: File): void {
        const copyParams = params || latestParams;
        if (!copyParams) {
            return;
        }
        latestParams = copyParams;

        if (copyParams.keyPath.length === 2) {
            const [, action] = copyParams.keyPath;
            if (action === Actions.LOAD_TASK_ANNO) {
                if (file) {
                    Modal.confirm({
                        title: 'Current annotation will be lost',
                        content: 'You are going to upload new annotations to this task. Continue?',
                        onOk: () => {
                            onClickMenu(copyParams, file);
                        },
                        okButtonProps: {
                            type: 'primary',
                            danger: true,
                        },
                        okText: 'Update',
                    });
                }
            } else {
                onClickMenu(copyParams);
            }
        } else if (copyParams.key === Actions.DELETE_TASK) {
            Modal.confirm({
                title: `The task ${taskID} will be deleted`,
                content: 'All related data (images, annotations) will be lost. Continue?',
                className: 'cvat-modal-confirm-delete-task',
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    type: 'primary',
                    danger: true,
                },
                okText: 'Delete',
            });
        } else if (copyParams.key === "info") {
            onInfoProject()
        } else {
            onClickMenu(copyParams);
        }
    }

    const onCloseModal = useCallback(() => {
        setIsModalVisible(false)
    }, [taskID]);

    const constructorTotalCounts = useCallback((data: any) => {
        if (data) {
            const reorderedObject: any = {};

            for (const key in data) {
                if (key !== "total_objects") {
                    reorderedObject[key] = data[key];
                }
            }

            reorderedObject.total_objects = data.total_objects;

            return Object.entries(reorderedObject)
                .map(([key, value]) => <p style={{ textTransform: 'capitalize' }}>{key} - {value}</p>)
        } else {
            return <p>An error occured</p>
        }
    }, [infoCounts])

    return (
        <>
            <Modal
                visible={isModalVisible}
                onClose={onCloseModal}
                onCancel={onCloseModal}
                onOk={onCloseModal}
                title="Total Info"
            >
                <div>
                    {
                        constructorTotalCounts(infoCounts)
                    }
                </div>
            </Modal>
            {client === true
                ? <Menu selectable={false} className='cvat-actions-menu' onClick={onClickMenuWrapper}>
                    {DumpSubmenu({
                        taskMode,
                        dumpers,
                        dumpActivities,
                        menuKey: Actions.DUMP_TASK_ANNO,
                        taskDimension,
                    })}
                    {ExportSubmenu({
                        exporters: dumpers,
                        exportActivities,
                        menuKey: Actions.EXPORT_TASK_DATASET,
                        taskDimension,
                    })}
                </Menu>

                : <Menu selectable={false} className='cvat-actions-menu' onClick={onClickMenuWrapper}>
                    {DumpSubmenu({
                        taskMode,
                        dumpers,
                        dumpActivities,
                        menuKey: Actions.DUMP_TASK_ANNO,
                        taskDimension,
                    })}
                    {LoadSubmenu({
                        loaders,
                        loadActivity,
                        onFileUpload: (file: File): void => {
                            onClickMenuWrapper(null, file);
                        },
                        menuKey: Actions.LOAD_TASK_ANNO,
                        taskDimension,
                    })}
                    {ExportSubmenu({
                        exporters: dumpers,
                        exportActivities,
                        menuKey: Actions.EXPORT_TASK_DATASET,
                        taskDimension,
                    })}
                    {!!bugTracker && <Menu.Item key={Actions.OPEN_BUG_TRACKER}>Open bug tracker</Menu.Item>}
                    <Menu.Item disabled={inferenceIsActive} key={Actions.RUN_AUTO_ANNOTATION}>
                        Automatic annotation
                    </Menu.Item>
                    <hr />
                    <Menu.Item key="info">Info</Menu.Item>
                    <Menu.Item key={Actions.DELETE_TASK}>Delete</Menu.Item>
                </Menu>}
        </>
    );
}