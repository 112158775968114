// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon from '@ant-design/icons';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Timeline from 'antd/lib/timeline';
import Dropdown from 'antd/lib/dropdown';
import { Popconfirm } from 'antd'

import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import {
    MainMenuIcon, SaveIcon, UndoIcon, RedoIcon,
} from 'icons';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { useDispatch } from 'react-redux';
import { onboardingActions } from 'actions/onboarding-actions';
import { Mixpanel, MIXPANEL_TRACK_KEYS } from 'services/mixpanel';

interface Props {
    saving: boolean;
    savingStatuses: string[];
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onGiveUpClick(): void;
    showGiveUp: boolean;
    isView: boolean;
    isUserClient: boolean;
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        savingStatuses,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onGiveUpClick,
        showGiveUp,
        isView = true,
        isUserClient
    } = props;


    const jobInstance = useSelector((state: CombinedState): any => state.annotation.job.instance);
    const { id } = useSelector((state: CombinedState) => state.auth.user)

    const onOk = (): void => {
        onGiveUpClick()
    }

    const onDiscord = () => {
        Mixpanel.track(MIXPANEL_TRACK_KEYS.CVAT_DISCORD, {
            "task_name": jobInstance.task.name,
            "task_id": jobInstance.task.id,
            "task_status": jobInstance.task.status,
            "task_verification": jobInstance.task.verification,
            "task_projectId": jobInstance.task.projectId,
            "user_id": id
        })
    }

    return (
        <Col className='cvat-annotation-header-left-group'>
            {isView && <CustomMenu />}
            {isView && <Button
                title={`Save current changes ${saveShortcut}`}
                onClick={saving ? undefined : onSaveAnnotation}
                type='link'
                className={saving ? 'cvat-annotation-disabled-header-button save-step' : 'cvat-annotation-header-button save-step'}
            >
                <Icon component={SaveIcon} />
                {saving ? 'Saving...' : 'Save'}
                <Modal title='Saving changes on the server' visible={saving} footer={[]} closable={false}>
                    <Timeline pending={savingStatuses[savingStatuses.length - 1] || 'Pending..'}>
                        {savingStatuses.slice(0, -1).map((status: string, id: number) => (
                            <Timeline.Item key={id}>{status}</Timeline.Item>
                        ))}
                    </Timeline>
                </Modal>
            </Button>}
            <Button
                title={`Undo: ${undoAction} ${undoShortcut}`}
                disabled={!undoAction}
                style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onUndoClick}
            >
                <Icon component={UndoIcon} />
                <span>Undo</span>
            </Button>
            <Button
                title={`Redo: ${redoAction} ${redoShortcut}`}
                disabled={!redoAction}
                style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onRedoClick}
            >
                <Icon component={RedoIcon} />
                Redo
            </Button>
            {showGiveUp && <Popconfirm
                title="Are you sure you want to forfeit this job?"
                onConfirm={onOk}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="primary"
                    danger
                    style={{ marginLeft: 10 }}
                >
                    Abandon job
                </Button>
            </Popconfirm>}
            {!isUserClient && <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                <span style={{ lineHeight: 1 }}>Не знаєш як розмітити? Запитай в</span>
                <Button onClick={() => onDiscord()} style={{ marginLeft: 10 }} type="primary"><a href="https://discord.gg/bQyR7CV9hC" target="_blank" rel="noopener">Discord</a></Button>
            </div>}
        </Col>
    );
}

export default React.memo(LeftGroup);

const CustomMenu = (): JSX.Element => {

    const { stepIndex, visibleMenu } = useSelector((state: CombinedState) => state.onboarding);
    const dispatch = useDispatch()

    function onVisibleDropDown(e: boolean) {
        if (e === true) {
            dispatch(onboardingActions.setVisibleMenu(e))
            dispatch(onboardingActions.setCurrentIndexIncrement())
        }
    }

    if (stepIndex > 0) {
        return <Dropdown visible={visibleMenu} onVisibleChange={onVisibleDropDown} overlay={<AnnotationMenuContainer />}>
            <Button type='link' className='cvat-annotation-header-button step-21'>
                <Icon component={MainMenuIcon} />
                Menu
            </Button>
        </Dropdown>
    }

    return (
        <Dropdown overlay={<AnnotationMenuContainer />}>
            <Button type='link' className='cvat-annotation-header-button'>
                <Icon component={MainMenuIcon} />
                Menu
            </Button>
        </Dropdown>
    )
}