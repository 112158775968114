import React, { useState } from 'react'
import { Row, Col, Typography, notification } from 'antd';
import patterns from 'utils/validation-patterns';

const { Text } = Typography;

interface IProps {
    onChange: (approximate_reward: number) => void;
    taskInstance: any;
}

const ApproximateReward = ({ onChange, taskInstance }: IProps) => {

    const [reward, setReward] = useState<number>(() => taskInstance.approximate_reward || 0);
    const [priceEditing, setPriceEditing] = useState<boolean>(false);

    const onStart = (): void => setPriceEditing(true);

    const onChangeValue = (value: string): void => {
        if (value && !patterns.onlyNumbers.pattern.test(value)) {
            notification.error({
                message: 'Could not edit a task',
                description: 'Approximate reward must be a number',
            });
            return
        }
        setReward(+value);
        setPriceEditing(false);
        onChange(+value);
    };

    return (
        <Row className='cvat-issue-tracker' style={{ marginTop: 20 }}>
            <Col>
                <Text strong className='cvat-text-color'>Approximate reward (USD)</Text>
                <br />
                <Text
                    editable={{
                        editing: priceEditing,
                        onStart,
                        onChange: onChangeValue,
                    }}
                >
                    {reward}
                </Text>
            </Col>
        </Row>
    )
}

export default ApproximateReward