// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import getCore from 'cvat-core-wrapper';
import { Invitation } from '../reducers/interfaces';

const core = getCore();

export enum UsersActionTypes {
    DELETE_USER = 'DELETE_USER',
    DELETE_USER_FAIL = 'DELETE_USER_FAIL',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    EDIT_USER = 'EDIT_USER',
    EDIT_USER_ADMIN = 'EDIT_USER_ADMIN',
    GET_USERS = 'GET_USERS',
    GET_USERS_FAILED = 'GET_USERS_FAILED',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
    GET_INVITES = 'GET_INVITES',
    GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS',
    GET_INVITES_FAIL = 'GET_INVITES_FAIL',
    SEND_INVITE = 'SEND_INVITE',
    SEND_INVITE_FAIL = 'SEND_INVITE_FAIL',
    SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS'
}

const usersActions = {
    getUsers: () => createAction(UsersActionTypes.GET_USERS),
    getUsersSuccess: ({ users, count, next }) => createAction(UsersActionTypes.GET_USERS_SUCCESS, { users, count, next }),
    getUsersFailed: (error: any) => createAction(UsersActionTypes.GET_USERS_FAILED, { error }),
    updateUser: (updatedUser: any) => createAction(UsersActionTypes.EDIT_USER, { updatedUser }),
    changeAdminStatus: (userID: number, shouldBeAdmin: boolean) =>
        createAction(UsersActionTypes.EDIT_USER_ADMIN, { userID, shouldBeAdmin }),
    deleteUser: () => createAction(UsersActionTypes.DELETE_USER, {}),
    deleteUserSuccess: (id: number) => createAction(UsersActionTypes.DELETE_USER_SUCCESS, { id }),
    deleteUserFail: () => createAction(UsersActionTypes.DELETE_USER_FAIL, {}),
    getInvites: () => createAction(UsersActionTypes.GET_INVITES, {}),
    getInvitesSuccess: (invitations: Invitation[]) =>
        createAction(UsersActionTypes.GET_INVITES_SUCCESS, { invitations }),
    getInvitesFail: (error: any) => createAction(UsersActionTypes.GET_INVITES_FAIL, { error }),
    sendInvite: () => createAction(UsersActionTypes.SEND_INVITE, {}),
    sendInviteSuccess: (results: Invitation[]) => createAction(UsersActionTypes.SEND_INVITE_SUCCESS, { results }),
    sendInviteFail: (message: string) => createAction(UsersActionTypes.SEND_INVITE_FAIL, { message })
};

export type UsersActions = ActionUnion<typeof usersActions>;

export function getUsersAsync(page = 1): ThunkAction {
    return async (dispatch): Promise<void> => {
        dispatch(usersActions.getUsers());

        try {
            const { users, count, next } = await core.users.get({ page: page });

            const userInstances = users.map((userData: any): any => new core.classes.User(userData));
            dispatch(usersActions.getUsersSuccess({ users: userInstances, count, next }));
        } catch (error) {
            dispatch(usersActions.getUsersFailed(error));
        }
    };
}

export function getInvitationsAsync(): ThunkAction {
    return async (dispatch): Promise<void> => {
        dispatch(usersActions.getInvites());
        try {
            const invitations: Invitation[] = await core.users.getInvitations();
            dispatch(usersActions.getInvitesSuccess(invitations));
        } catch (error) {
            dispatch(usersActions.getInvitesFail(error));
        }
    };
}

export function sendInvite(emails: string[]): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(usersActions.sendInvite());
            const results = await core.users.invite(emails);

            let isSuccess = true;
            for (const inviteData of results) {
                if (Array.isArray(inviteData.non_field_errors) && typeof inviteData.non_field_errors[0] === 'string') {
                    dispatch(usersActions.sendInviteFail(inviteData.non_field_errors[0]));
                    isSuccess = false;
                }
                if (Array.isArray(inviteData.email) && typeof inviteData.email[0] === 'string') {
                    dispatch(usersActions.sendInviteFail(inviteData.email[0]));
                    isSuccess = false;
                }
            }
            if (isSuccess) {
                dispatch(usersActions.sendInviteSuccess(results));
            }
        } catch (error) {
            dispatch(usersActions.sendInviteFail('An error happened sending invites.'));
        }
    };
}

export function deleteUser(userID: number): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(usersActions.deleteUser());
            await core.users.deleteUser(userID);
            dispatch(usersActions.deleteUserSuccess(id));
        } catch (error) {
            dispatch(usersActions.deleteUserFail());
        }
    };
}

export function editUsersAdminStatus(userID: number, shouldBeAdmin: boolean): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(usersActions.changeAdminStatus(userID, shouldBeAdmin));
            const updatedUser = await core.users.editUsersAdminStatus(userID, shouldBeAdmin);
            dispatch(usersActions.updateUser(updatedUser));
        } catch (error) {
            dispatch(usersActions.changeAdminStatus(userID, !shouldBeAdmin));
        }
    };
}
