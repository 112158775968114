// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { getRewardingAsync, showStatistics } from 'actions/annotation-actions';
import StatisticsModalComponent from 'components/annotation-page/top-bar/statistics-modal';
import { getTimeTrackerAsync } from '../../../actions/annotation-actions';

interface StateToProps {
    visible: boolean;
    collecting: boolean;
    data: any;
    jobInstance: any;
    jobStatus: string;
    savingJobStatus: boolean;
    rewarding: any;
    timeTrackerResult: any
}

interface DispatchToProps {
    closeStatistics(): void;
    getRewarding(jid: number): void;
    getTime(jid: number): void;
}

function timeTrackerResultForUser(timeTracker: any, jobInstance: any, user: any) {
    if (!timeTracker || !jobInstance || !user) {
        return null
    }

    let { time_annotator, time_reviewer } = timeTracker;
    let userID = user.id;
    let reviewerID = jobInstance.reviewer?.id;
    let assigneeID = jobInstance.assignee?.id;

    if (userID === reviewerID) {
        return [{ type: 'Time Reviewer', value: time_reviewer }]
    }
    if (userID === assigneeID) {
        return [{ type: 'Time Annotator', value: time_annotator }]
    }
    return [
        { type: 'Time Annotator', value: time_annotator },
        { type: 'Time Reviewer', value: time_reviewer }
    ]
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            statistics: { visible, collecting, data },
            job: {
                saving: savingJobStatus,
                instance: { status: jobStatus },
                instance: jobInstance,
            },
            rewarding,
            timeTracker
        }
    } = state;
    const user = state.auth.user

    return {
        visible,
        collecting,
        data,
        jobInstance,
        jobStatus,
        savingJobStatus,
        rewarding,
        timeTrackerResult: timeTrackerResultForUser(timeTracker, jobInstance, user)
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        closeStatistics(): void {
            dispatch(showStatistics(false));
        },
        getRewarding(jid: number): void {
            dispatch(getRewardingAsync(jid))
        },
        getTime(jid: number): void {
            dispatch(getTimeTrackerAsync(jid))
        }
    };
}

type Props = StateToProps & DispatchToProps;

class StatisticsModalContainer extends React.PureComponent<Props> {

    public render(): JSX.Element {
        const {
            jobInstance, visible, collecting, data, closeStatistics, jobStatus, savingJobStatus, rewarding, getRewarding, getTime, timeTrackerResult
        } = this.props;

        return (
            <StatisticsModalComponent
                collecting={collecting}
                data={data}
                visible={visible}
                jobStatus={jobStatus}
                bugTracker={jobInstance.task.bugTracker}
                startFrame={jobInstance.startFrame}
                stopFrame={jobInstance.stopFrame}
                assignee={jobInstance.assignee ? jobInstance.assignee.username : 'Nobody'}
                reviewer={jobInstance.reviewer ? jobInstance.reviewer.username : 'Nobody'}
                savingJobStatus={savingJobStatus}
                closeStatistics={closeStatistics}
                statistic={rewarding}
                jid={jobInstance.id}
                getRewarding={getRewarding}
                getTime={getTime}
                timeTrackerResult={timeTrackerResult}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsModalContainer);
