import React from 'react'
import { onboardingActions } from 'actions/onboarding-actions';
import { useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import SETTINGS from './assets/settings.gif'
import CORRECT from './assets/correct.gif'
import WRONG from './assets/wrong.gif'
import DELETE from './assets/delete.gif'
import CORRECTION from './assets/correction.gif'
import POLYLINE from './assets/polyline.gif'
import POLYGON from './assets/polygon.gif'
import REVIEW from './assets/review.gif'
import KEYPOINT from './assets/keypoint.gif'
import WRONG_ICON from './assets/wrong-icon.svg'
import CORRECT_ICON from './assets/correct-icon.svg'

import './styles.scss'
import { modalStepsFullWidth, StepsTarget } from './onboarding-confiig';

const WRONG_ICON_SVG = React.memo((): JSX.Element => <WRONG_ICON />);
const CORRECT_ICON_SVG = React.memo((): JSX.Element => <CORRECT_ICON />);


const Onboarding = (props: any) => {

    const paramsHide = {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightPadding: 0
    }

    const paramsOther = {
        disableBeacon: true,
        showSkipButton: false,
        hideCloseButton: true
    }


    const transition_popover = {
        spotlightPadding: 1,
        scrollOffset: 0,
        scrollDuration: 0,
        placement: 'top',
    }

    const STEPS = [
        {
            target: `.${StepsTarget.STEP_1}`,
            content: <div className="onboarding-1">
                <h1>Welcome to CVAT. Let’s set your settings up before we get to work. Please, follow the instructions you see in the GIF below:</h1>
                <img src={SETTINGS} alt="Settings" />
            </div>,
            placement: 'center',
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true
        },
        {
            target: `.${StepsTarget.STEP_2}`,
            content: 'Choose rectangle tool from the list.',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
        },
        {
            target: `.${StepsTarget.STEP_3}`,
            content: 'Set label and drawing method as in the example. Choose “Shape option”.',
            ...paramsHide,
            spotlightClicks: true,
            hideBackButton: true,
            ...transition_popover
        },
        {
            target: `.${StepsTarget.STEP_4}`,
            content: <div className="onboarding-4">
                <h1> Check the correct and wrong ways of  annotation with rectangle tool below. Make sure you annotate the whole item.</h1>
                <div className="wrap">
                    <div className="correct">
                        <h2>Correct</h2>
                        <CORRECT_ICON_SVG />
                        <img src={CORRECT} alt="Correct" />
                        <p>Make sure the whole object is properly annotated.</p>
                    </div>
                    <div className="wrong">
                        <h2>Wrong</h2>
                        <WRONG_ICON_SVG />
                        <img src={WRONG} alt="Wrong" />
                        <p>If the object is partially outlined or has big margins, the annotation is not accepted</p>
                    </div>
                </div>

            </div>,
            placement: 'center',
            hideBackButton: true,
            ...paramsOther
        },
        {
            target: `.${StepsTarget.STEP_5}`,
            content: (
                <div className="onboarding-1">
                    <h1>To delete an object, click the right button on your mouse while in rectangle area. Hover 3-dots icon and choose ‘remove’ option as in the example.</h1>
                    <img src={DELETE} alt="Delete" />
                </div>
            ),
            placement: 'center',
            hideBackButton: false,
            ...paramsOther
        },
        {
            target: `.${StepsTarget.STEP_6}`,
            content: (
                <div className="onboarding-1">
                    <h1>Already created annotation rectangles can be edited as in the example below:</h1>
                    <img src={CORRECTION} alt="Correction" />
                </div>
            ),
            placement: 'center',
            hideBackButton: false,
            ...paramsOther
        },
        {
            target: `.${StepsTarget.STEP_7}`,
            content: 'Label all the cars on the frame using the rectangle tool. Click on the Finish button once completed',
            placement: 'center',
            ...paramsOther,
            hideBackButton: true
        },
        {
            target: `.${StepsTarget.STEP_8}`,
            content: 'Go to the second frame to explore polygon tool.',
            placement: 'right',
            ...paramsHide,
            hideBackButton: true,
            spotlightClicks: true
        },
        {
            target: `.${StepsTarget.STEP_9}`,
            content: 'Choose polygon tool from the list.',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
        },
        {
            target: `.${StepsTarget.STEP_10}`,
            content: 'Set label and drawing method as in the example. Leave the number of dots field empty. Choose “Shape option”.',
            ...paramsHide,
            spotlightClicks: true,
            hideBackButton: true,
            ...transition_popover
        },
        {
            target: `.${StepsTarget.STEP_11}`,
            content: <div className="onboarding-1">
                <h1>Hold the ‘Shift’ key and start annotating the border of the rock. To complete your annotation, hit ‘n’ button on the keyboard in English language or hit the tool icon.  Please, note that until you hit the ‘n’ button or hit the tool icon all your polygon will be considered as 1 object.</h1>
                <img src={POLYGON} alt="Polygon" />
            </div>,
            placement: 'center',
            ...paramsOther,
            hideBackButton: true
        },
        {
            target: `.${StepsTarget.STEP_12}`,
            content: 'To finish this step hit the “Next frame” button. ',
            placement: 'right',
            ...paramsHide,
            hideBackButton: true,
            spotlightClicks: true
        },
        {
            target: `.${StepsTarget.STEP_13}`,
            content: 'Choose polyline tool from the list.',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
        },
        {
            target: `.${StepsTarget.STEP_14}`,
            content: 'Set label and drawing method as in the example. Leave the number of dots field empty. Choose “Shape option”.',
            ...paramsHide,
            spotlightClicks: true,
            hideBackButton: true,
            ...transition_popover
        },
        {
            target: `.${StepsTarget.STEP_15}`,
            content: <div className="onboarding-1">
                <h1>You either create points by clicking or by dragging a mouse on the screen while holding Shift. Press N again or click the Done button on the top panel to complete the shape.</h1>
                <img src={POLYLINE} alt="Polyline" />
            </div>,
            placement: 'center',
            ...paramsOther,
            hideBackButton: true
        },
        {
            target: `.${StepsTarget.STEP_16}`,
            content: 'To finish this step hit the “Next frame” button. ',
            placement: 'right',
            ...paramsHide,
            hideBackButton: true,
            spotlightClicks: true
        },
        {
            target: `.${StepsTarget.STEP_17}`,
            content: 'Choose key points tool from the list.',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
        },
        {
            target: `.${StepsTarget.STEP_18}`,
            content: 'Choose shape. Leave the ‘number of points’ field empty.',
            ...paramsHide,
            spotlightClicks: true,
            hideBackButton: true,
            ...transition_popover
        },
        {
            target: `.${StepsTarget.STEP_19}`,
            content: <div className="onboarding-1 onboarding-key">
                <h1>Use key points tool to annotate the facial features as in the example below. To complete your annotation, hit ‘n’ button on the keyboard in English language or hit the tool icon. Please, note that until you hit the ‘n’ button or hit the tool icon all your keypoints will be considered as 1 object. </h1>
                <img src={KEYPOINT} alt="KEYPOINT" />
            </div>,
            placement: 'center',
            ...paramsOther,
            hideBackButton: true
        },
        {
            target: `.${StepsTarget.STEP_20}`,
            content: <div className="onboarding-1">
                <h1>After you finish your annotations you’ll need to submit them to review. Please, follow instructions below to sub it your annotations.</h1>
                <img src={REVIEW} alt="REVIEW" />
            </div>,
            placement: 'center',
            ...paramsOther,
            hideBackButton: true
        },
        {
            target: '.save-step',
            content: 'Save',
            placement: 'right',
            ...paramsHide,
            hideBackButton: true,
            spotlightClicks: true
        },
        {
            target: `.${StepsTarget.STEP_21}`,
            content: 'Open',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
            event: 'hover'
        },
        {
            target: `.${StepsTarget.STEP_22}`,
            content: 'Submit annotations',
            placement: 'right',
            ...paramsHide,
            spotlightClicks: true,
        },
        {
            target: `.${StepsTarget.STEP_23}`,
            content: 'Submit confirm',
            placement: 'bottom',
            ...paramsHide,
            spotlightClicks: true,
        }
    ]

    const { steps, run, stepIndex, start_onboarding, enabled } = useSelector((state: CombinedState) => state.onboarding)
    const { user } = useSelector((state: CombinedState) => state.auth)
    const dispatch = useDispatch();

    useEffect(() => {
        if (steps.length === 0) {
            dispatch(onboardingActions.setSteps(STEPS))
        }
    }, [])

    useEffect(() => {
        start_onboarding === true && dispatch(onboardingActions.setRun(true))
    }, [start_onboarding])


    useEffect(() => {
        if (stepIndex === 23) {
            removeCancelBtn()
        }
    }, [stepIndex])

    function removeCancelBtn(): void {
        setTimeout(() => {
            let modal = document.querySelector(`.${StepsTarget.STEP_23}`);
            let btn = modal?.querySelectorAll('button');
            let btn_close_modal: any = document.querySelector('.ant-modal-close-x')
            if (btn) {
                btn[1].style.display = 'none';
            }
            if (btn_close_modal) {
                btn_close_modal[0].style.display = 'none';
            }
        }, 100)
    }

    const handleJoyrideCallback = (data: any): void => {
        const { action, index, status, type } = data;

        if (enabled === true) return

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            let step = index + (action === ACTIONS.PREV ? -1 : 1)

            if (step === 7 || step === 11 || step === 15 || step === 19) {
                dispatch(onboardingActions.setRun(false))
                dispatch(onboardingActions.setFinishOnboarding(false))
                dispatch(onboardingActions.setEnabled(true))
                return
            }
            dispatch(onboardingActions.setCurrentIndex(step))
        }

        if (action === ACTIONS.SKIP) {
            dispatch(onboardingActions.setRun(false))
        }

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            dispatch(onboardingActions.setRun(false))
            dispatch(onboardingActions.setCurrentIndex(0))
            // dispatch(onboardingActions.setFinishOnboarding(false))
        }
    };

    const calculateWidth = (): number => {
        if (modalStepsFullWidth.includes(stepIndex)) return 902;
        if ([4].includes(stepIndex)) return 700;

        return 400
    }

    return (
        <>
            {
                user.onboarding === false && run
                    ? <Joyride
                        callback={handleJoyrideCallback}
                        steps={steps}
                        run={run}
                        stepIndex={stepIndex}
                        continuous
                        scrollToFirstStep
                        showProgress
                        showSkipButton
                        disableOverlayClose={true}
                        disableCloseOnEsc={true}
                        scrollOffset={0}
                        scrollDuration={50}
                        styles={{
                            buttonNext: {
                                backgroundColor: '#1890FF',
                                borderRadius: 4,
                                color: '#ffffff',
                                outline: 'none',
                                border: `1px solid #1890FF`,
                                padding: `10px 30px`
                            },
                            buttonBack: {
                                backgroundColor: '#ffffff',
                                borderRadius: 4,
                                color: '#1890FF',
                                borderColor: '#1890FF',
                                borderWidth: 1
                            },
                            buttonSkip: {
                                backgroundColor: '#ffffff',
                                borderRadius: 4,
                                color: '#1890FF',
                                borderColor: '#1890FF',
                                borderWidth: 1
                            },
                            options: {
                                arrowColor: '#ffffff',
                                backgroundColor: '#ffffff',
                                beaconSize: 36,
                                overlayColor: 'rgb(51 50 50 / 87%)',
                                primaryColor: '#000000',
                                spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                                textColor: '#000000',
                                width: calculateWidth(),
                                zIndex: 90000,
                            }
                        }}
                    />
                    : <></>
            }
        </>
    )
}

export default Onboarding