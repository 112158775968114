// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useLayoutEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import { LydRedLogoIcon } from '@/icons/';

import LoginForm, { LoginData } from './login-form';
import { RemoterLogo } from 'icons';
import { useDispatch } from 'react-redux';
import { chechOneTimeTokenAsync } from 'actions/auth-actions';
import { Spin } from 'antd';

interface LoginPageComponentProps {
    fetching: boolean;
    renderResetPassword: boolean;
    onLogin: (username: string, password: string) => void;
}

function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {

    const { fetching, onLogin, location } = props;
    const [isHash, setIsHash] = useState(false)

    const dispatch = useDispatch()

    useLayoutEffect(() => {

        let hash = location.hash;

        const fn = async () => {
            let parsedHash = hash.replace('#', '').trim();

            let response = await dispatch(chechOneTimeTokenAsync(parsedHash));
            setIsHash(false)

            if (response === 404) {
                window.history.replaceState("", document.title, window.location.pathname + window.location.search);
            }
        }

        if (hash) {
            setIsHash(true)
            try {
                fn();
            } catch (error) {
                setIsHash(false)
            }
        }
    }, [])

    const columnStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 1,
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        ...columnStyle,
                        flex: 'none',
                        flexDirection: 'initial',
                        width: '42.29vw',
                    }}
                >
                    <div
                        style={{
                            width: '23.61vw',
                            marginLeft: '9.58vw',
                            marginTop: '4.15vw',
                        }}
                    >
                        <div
                            style={{
                                width: '12.45vw',
                                position: 'relative',
                                left: '-0.25vw',
                                top: '0.055vw',
                            }}
                        >
                            {/* <LydRedLogoIcon /> */}
                        </div>
                        <div
                            style={{
                                marginTop: '7.75vw',
                            }}
                        >
                            <h1 style={{ marginBottom: 90, fontSize: 25 }}>Use the same username and password you used to log in to the platform.</h1>
                            {isHash
                                ? <Spin />
                                : <LoginForm
                                    fetching={fetching}
                                    onSubmit={(loginData: LoginData): void => {
                                        onLogin(loginData.username, loginData.password);
                                    }}
                                />}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        ...columnStyle,
                        flexDirection: 'initial',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'linear-gradient(135deg, #40D19D, #4D8664)',
                    }}
                >
                    <div className="remoter">
                        <RemoterLogo />
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(LoginPageComponent);
