// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { AnyAction } from 'redux';
import { ProjectsActionTypes } from 'actions/projects-actions';
import { BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActionTypes } from 'actions/auth-actions';

import { Project, ProjectsState } from './interfaces';

const defaultState: ProjectsState = {
    initialized: false,
    fetching: false,
    count: 0,
    current: [],
    gettingQuery: {
        page: 1,
        id: null,
        search: null,
        owner: null,
        name: null,
        status: null,
    },
    activities: {
        deletes: {},
        creates: {
            id: null,
            error: '',
        },
    },
    infoCounts: null,
    infoError: null,
    infoFetching: false,
    dumpFetching: false,
    projectsForNewtask: {
        list: [],
        loading: false,
        error: null
    }
};

export default (state: ProjectsState = defaultState, action: AnyAction): ProjectsState => {
    switch (action.type) {
        case ProjectsActionTypes.UPDATE_PROJECTS_GETTING_QUERY:
            return {
                ...state,
                gettingQuery: {
                    ...defaultState.gettingQuery,
                    ...action.payload.query,
                },
            };
        case ProjectsActionTypes.GET_PROJECTS:
            return {
                ...state,
                initialized: false,
                fetching: true,
                count: 0,
                current: [],
            };
        case ProjectsActionTypes.GET_PROJECTS_SUCCESS: {
            return {
                ...state,
                initialized: true,
                fetching: false,
                count: action.payload.count,
                current: action.payload.array,
            };
        }
        case ProjectsActionTypes.GET_PROJECTS_FAILED: {
            return {
                ...state,
                initialized: true,
                fetching: false,
            };
        }
        case ProjectsActionTypes.CREATE_PROJECT: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    creates: {
                        id: null,
                        error: '',
                    },
                },
            };
        }
        case ProjectsActionTypes.CREATE_PROJECT_FAILED: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        error: action.payload.error.toString(),
                    },
                },
            };
        }
        case ProjectsActionTypes.CREATE_PROJECT_SUCCESS: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    creates: {
                        id: action.payload.projectId,
                        error: '',
                    },
                },
            };
        }
        case ProjectsActionTypes.UPDATE_PROJECT: {
            return {
                ...state,
            };
        }
        case ProjectsActionTypes.UPDATE_PROJECT_SUCCESS: {
            return {
                ...state,
                current: state.current.map(
                    (project): Project => {
                        if (project.id === action.payload.project.id) {
                            return action.payload.project;
                        }

                        return project;
                    },
                ),
            };
        }
        case ProjectsActionTypes.UPDATE_PROJECT_FAILED: {
            return {
                ...state,
                current: state.current.map(
                    (project): Project => {
                        if (project.id === action.payload.project.id) {
                            return action.payload.project;
                        }

                        return project;
                    },
                ),
            };
        }
        case ProjectsActionTypes.DELETE_PROJECT: {
            const { projectId } = action.payload;
            const { deletes } = state.activities;

            deletes[projectId] = false;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                    },
                },
            };
        }
        case ProjectsActionTypes.DELETE_PROJECT_SUCCESS: {
            const { projectId } = action.payload;
            const { deletes } = state.activities;

            deletes[projectId] = true;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                    },
                },
            };
        }
        case ProjectsActionTypes.DELETE_PROJECT_FAILED: {
            const { projectId } = action.payload;
            const { deletes } = state.activities;

            delete deletes[projectId];

            return {
                ...state,
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                    },
                },
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        case ProjectsActionTypes.INFO_PROJECT_SUCCESS: {
            return {
                ...state,
                infoCounts: action.payload.infoCount,
                infoFetching: false
            }
        }
        case ProjectsActionTypes.INFO_PROJECT_ERROR: {
            return {
                ...state,
                infoError: action.payload.error.message,
                infoFetching: false
            }
        }
        case ProjectsActionTypes.INFO_PROJECT: {
            return { ...state, infoFetching: !state.infoFetching }
        }
        case ProjectsActionTypes.INFO_PROJECT_CLEAR: {
            return { ...state, infoError: null, infoCounts: null }
        }
        case ProjectsActionTypes.DUMP_FETCH: {
            return { ...state, dumpFetching: !state.dumpFetching }
        }
        case ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK: {
            return {
                ...state,
                projectsForNewtask: {
                    ...state.projectsForNewtask,
                    loading: !state.projectsForNewtask.loading
                }
            }
        }
        case ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK_SUCCESS: {
            const { projects } = action.payload;
            return {
                ...state,
                projectsForNewtask: {
                    error: null,
                    list: projects,
                    loading: false
                }
            }
        }
        case ProjectsActionTypes.PROJECTS_FOR_ASK_FOR_NEW_TASK_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                projectsForNewtask: {
                    error: error,
                    list: [],
                    loading: false
                }
            }
        }
        default:
            return state;
    }
};
