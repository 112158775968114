import React from 'react'
import { Form, InputNumber } from 'antd';

interface IRewardPErObjectProps {
    type: 'track' | 'shape' | 'issue';
    role: 'annotator' | 'reviewer';
    element: string;
}

const RewardPerObjectFormItem = ({ element, type, role }: IRewardPErObjectProps) => {
    return (
        <Form.Item
            style={{ width: '48%' }}
            name={`${role}_${type}_${element}`}
            label={type === 'issue' ? 'Issue' : element + `(${type})`}
            hasFeedback
            initialValue={0}
            rules={[{ required: true, message: 'Required' }]}
        >
            <InputNumber size="small" defaultValue={0} min={0} />
        </Form.Item>
    )
}

export default RewardPerObjectFormItem